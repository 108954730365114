import React, {useEffect} from 'react';

const Unauthorized = () => {

  useEffect(() => {
    document.title = `Нет доступа к странице`;
    document.getElementsByTagName("META")[3].content = "К сожалению у вас нет доступа к данной странице"
  }, []);
  return (
      <div className="container">
        <p>Для просмотра этой страницы вам необходимо войти в аккаунт</p>
      </div>
  );
};

export default Unauthorized;