import React from 'react';
import styles from '../styles/modules/RegisterAlarm.module.scss'
import {Link} from "react-router-dom";

const RegisterAlarm = () => {

  return (
    <div className={styles.alarm}>
      <p>Для использования этой функции необходимо <Link className={styles.link} to={'/registration'}> <span>зарегистрироваться</span></Link></p>
    </div>
  );
};

export default RegisterAlarm;